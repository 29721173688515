/* all pages */

:root {
  color-scheme: dark;
}

html,
body {
  height: 100%;
  background-color: #444444;
}

#root {
  min-height: 100%;
  color: #FFFFFF;
  background-color: #444444;
}

.logo-gradient {
  mask-image: linear-gradient(to left top, black 40%, transparent 100%);
}



/* main page */

.main-image {
  width: min(450px, 90%);
  display: block;
  margin: auto;
}



/* inertia page */

.centered-checkbox {
  display: inline-block;
}

.chart-container {
  height: max(50vh, 250px);
}

.leaflet-container {
  width: 100%;
  border-radius: 10px;
}

.map-container {
  height: 100%;
  min-height: max(33vh, 200px);
}

.leaflet-container img.leaflet-tile {
  outline: 1px solid transparent;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-start;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
}

.sub-box {
  margin-top: 1rem;
  padding: 1rem;
  border: solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  box-shadow: 0 20px 50px rgb(255, 255, 255, 0.05);
}

.my-progress {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  background-color: #444444 !important;
  height: 1.5rem !important;
}

.inertia-table-row {
  height: 3rem;
}

/* https: //codepen.io/basswolfe/pen/aMgEdX */
i:hover span {
  animation-name: signal-intro;
  animation-play-state: running;
}

.icon__signal-strength {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: auto;
  height: 1.5rem;
  margin-top: 3px;
}

.icon__signal-strength span {
  display: inline-block;
  width: 0.3rem;
  margin-left: 2px;
  transform-origin: 100% 100%;
  background-color: #00cc00;
  border-radius: 2px;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.3);
  animation-fill-mode: both;
  animation-play-state: paused;
}

.icon__signal-strength .bar-1 {
  height: 25%;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
}

.icon__signal-strength .bar-2 {
  height: 50%;
  animation-duration: 0.25s;
  animation-delay: 0.2s;
}

.icon__signal-strength .bar-3 {
  height: 75%;
  animation-duration: 0.2s;
  animation-delay: 0.3s;
}

.icon__signal-strength .bar-4 {
  height: 100%;
  animation-duration: 0.15s;
  animation-delay: 0.4s;
}

.signal-0 .bar-1,
.signal-0 .bar-2,
.signal-0 .bar-3,
.signal-0 .bar-4 {
  opacity: 0.2;
}

.signal-1 .bar-2,
.signal-1 .bar-3,
.signal-1 .bar-4 {
  opacity: 0.2;
}

.signal-2 .bar-3,
.signal-2 .bar-4 {
  opacity: 0.2;
}

.signal-3 .bar-4 {
  opacity: 0.2;
}

@keyframes signal-intro {
  from {
    opacity: 0.2;
    height: 2px;
  }
}



/* overview page */

.my-cardGrid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(95%, 1fr));
  padding: 1rem;
}

.my-card {
  border: solid white;
  border-radius: 20px;
  box-shadow: 0 20px 50px rgb(255, 255, 255, 0.05);
  display: flex;
  flex-flow: wrap;
  align-content: center;
}

.my-card:hover {
  box-shadow: 0 20px 50px rgb(255, 255, 255, 0.5);
  cursor: zoom-in;
}

.my-item {
  flex: 1 100%;
  text-align: center;
}

.my-sub-item {
  display: flex;
  line-height: 1.2;
}

.info-table-1 {
  margin-left: 20px;
  table-layout: fixed;
  width: calc(100% - 40px);
}

.info-table-2 {
  margin-top: 15px;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 0px solid transparent;
}

.info-table-2 tr>td+td {
  border-left-width: 10px;
}



/* measurement list */

.DataGrid-sub-cell {
  min-height: 52px;
  align-content: center;
}

.DataGrid-cell {
  min-height: 52px;
  height: 100%;
  align-content: center;
  font-size: 1rem;
}

.MuiDataGrid-cell:focus {
  outline: none !important
}

.MuiDataGrid-cell:focus-within {
  outline: none !important
}

.MuiDataGrid-columnHeader:focus {
  outline: none !important
}

.details-card-grid {
  display: grid;
  grid-template-columns: calc(65% - 1rem) calc(35% - 1rem);
  gap: 2rem
}

@media (max-width: 1000px) {
  .details-card-grid {
    grid-template-columns: 1fr;
  }
}



/* bootstrap stuff */

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.align-middle {
  vertical-align: middle !important;
}

.bg-success {
  background-color: rgba(25, 135, 84, 1) !important;
}

.bg-danger {
  background-color: rgba(220, 53, 69, 1) !important;
}

.form-check-input {
  font-size: inherit;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #495057;
  border-radius: 0.25em;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.progress {
  vertical-align: baseline;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.375rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}

td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-color: transparent;
}

.table {
  border-collapse: collapse;
  color: #fff;
  border-color: #4d5154;
  background-color: #212529;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.table> :not(caption)>*>* {
  border-bottom-width: 1px;
  padding: 0.25rem 0.25rem;
}

.table>tbody>tr:nth-of-type(odd)>* {
  background-color: #2c3034;
}
